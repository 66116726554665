/**
 * @typedef {Object} Page
 * @property  {string}                    page_name             The display name of the page used for internal messaging;
 *                                                              see `translations.json` for display name used in the UI
 * @property  {string}                    [text_key]            The i18n key for the page name
 * @property  {string}                    [url_page]            The relative URL of the page
 * @property  {string}                    [visible_page_name]   The name of the page as it appears in the URL. It is
 *                                                              required to make the page appear in nav_menu.
 * @property  {string}                    [name_icon]           The path to the icon for the page
 * @property  {string}                    [element_id]          The id attribute for the root element of the page
 * @property  {string}                    [resource]            The resource claim required to access the page; omit
 *                                                              if the page is required to be accessible to all type
 *                                                              of users.
 * @property  {boolean}                   show_in_nav           Whether or not the page should appear in the nav menu
 * @property  {Object.<string, string>}   url_localization      The localized URLs for the page
 */

/** @constant
 * @type {Page}
 */
export const HOME_PAGE = {
  page_name: 'Dashboard',
  text_key: 'common.navigation.dashboard',
  url_page: 'dashboard',
  visible_page_name: 'dashboard',
  name_icon: 'icons/dashboard-{0}.svg',
  element_id: 'dashboard-button',
  show_in_nav: true,
  url_localization: {
    en: 'dashboard',
    fr: 'tableau_de_bord',
  },
};

/** @constant
 * @type {Page[]}
 */
export const PAGES = [
  HOME_PAGE,
  {
    page_name: 'Library',
    text_key: 'common.navigation.library',
    url_page: 'resources',
    visible_page_name: 'resources',
    name_icon: 'icons/library-{0}.svg',
    element_id: 'library-button',
    show_in_nav: true,
    url_localization: {
      en: 'resources',
      fr: 'ressources',
    },
  },
  {
    page_name: 'Favorites',
    text_key: 'common.navigation.favorites',
    url_page: 'favorites',
    visible_page_name: 'my_sage',
    name_icon: 'icons/favorites-{0}.svg',
    element_id: 'favorites-button',
    show_in_nav: true,
    url_localization: {
      en: 'favorites',
      fr: 'favoris',
    },
  },
  {
    page_name: 'User Snippets',
    text_key: 'common.navigation.user_snippets',
    url_page: 'user_snippets',
    visible_page_name: 'user_snippets',
    name_icon: 'icons/user-snippets-{0}.svg',
    show_in_nav: true,
    url_localization: {
      en: 'user_snippets',
      fr: 'utilisateur_extraits',
    },
  },
  {
    page_name: 'Collections',
    text_key: 'common.navigation.collections',
    url_page: 'collections',
    visible_page_name: 'collections',
    name_icon: 'icons/collections-{0}.svg',
    show_in_nav: true,
    url_localization: {
      en: 'collections',
      fr: 'collections',
    },
  },
  {
    page_name: 'Comparisons',
    text_key: 'common.navigation.comparisons',
    url_page: 'comparisons',
    visible_page_name: 'comparisons',
    name_icon: 'icons/comparisons-on.svg',
    show_in_nav: true,
    url_localization: {
      en: 'comparisons',
      fr: 'comparisons',
    },
  },
  {
    page_name: 'Knowledge',
    text_key: 'common.navigation.knowledge',
    url_page: 'knowledge',
    visible_page_name: 'knowledge',
    name_icon: 'icons/knowledge-{0}.svg',
    resource: 'portal:knowledge',
    show_in_nav: true,
    url_localization: {
      en: 'knowledge',
      fr: 'connaissances',
    },
  },
  {
    page_name: 'Announcements',
    text_key: 'common.navigation.announcements',
    url_page: 'announcements',
    visible_page_name: 'announcements',
    name_icon: 'icons/announcements-{0}.svg',
    element_id: 'announcements-button',
    show_in_nav: true,
    url_localization: {
      en: 'announcements',
      fr: 'communiqués',
    },
  },
  {
    page_name: 'Users',
    text_key: 'common.navigation.users',
    url_page: 'users',
    visible_page_name: 'users',
    name_icon: 'icons/users-{0}.svg',
    resource: 'portal:admin',
    show_in_nav: true,
    url_localization: {
      en: 'users',
      fr: 'utilisateurs',
    },
  },
  {
    page_name: 'Support',
    text_key: 'common.navigation.support',
    url_page: 'support',
    visible_page_name: 'support',
    name_icon: 'icons/support-{0}.svg',
    element_id: 'support-button',
    show_in_nav: true,
    url_localization: {
      en: 'support',
      fr: 'soutien',
    },
  },
  {
    page_name: 'Create Password',
    show_in_nav: false,
    url_localization: {
      en: 'create_password',
      fr: 'créer_mot_de_passe',
    },
  },
  {
    page_name: 'Forgot Password',
    show_in_nav: false,
    url_localization: {
      en: 'forgot_password',
      fr: 'mot_de_passe_oublié',
    },
  },
  {
    page_name: 'Login',
    show_in_nav: false,
    url_localization: {
      en: 'log_in',
      fr: 'connexion',
    },
  },
  {
    page_name: 'Resource Management',
    show_in_nav: false,
    url_localization: {
      en: 'resource_management',
      fr: 'gestion_de_ressources',
    },
  },
  {
    page_name: 'Success Forgot Password',
    show_in_nav: false,
    url_localization: {
      en: 'success_forgot_password',
      fr: 'mot_de_passe_oublié_réussi',
    },
  },
  {
    page_name: 'Document Viewer',
    show_in_nav: false,
    url_localization: {
      en: 'doc_viewer',
      fr: 'visionneuse_de_documents',
    },
  },
  {
    page_name: 'Results',
    show_in_nav: false,
    url_localization: {
      en: 'results',
      fr: 'résultats',
    },
  },
  {
    page_name: 'Links',
    show_in_nav: false,
    url_localization: {
      en: 'links',
      fr: 'liens',
    },
  },
  {
    page_name: 'Collection Sheet',
    show_in_nav: false,
    url_localization: {
      en: 'collection',
      fr: 'collection',
    },
  },
];

/**
 * @param {string} path the path of the desired page
 * @returns {string} the page name or the empty string if the page is not found
 */
export const getPageName = (path) => {
  const page = PAGES.find((page) => Object.values(page.url_localization).some((url) => path.endsWith(url)));
  return page ? page.page_name : '';
};
